<template>
  <v-container>
    <div>
      <v-form ref="Register" v-model="registerValid" lazy-validation>
        <v-text-field
          outlined
          class="mt-2"
          label="Firstname"
          prepend-inner-icon="mdi-account-circle"
          v-model="firstname"
          dense
          :rules="[rules.required]"
        />

        <v-text-field
          outlined
          label="Surname"
          prepend-inner-icon="mdi-account-circle"
          v-model="surname"
          dense
          :rules="[rules.required]"
        />

        <v-text-field
          outlined
          label="Email"
          prepend-inner-icon="mdi-email"
          v-model="email"
          dense
          :rules="[rules.required, rules.email]"
        />
        <v-text-field
          outlined
          label="Mobile"
          prepend-inner-icon="mdi-phone"
          v-model="mobile"
          dense
          v-mask="'#### ### ###'"
          :rules="[rules.required]"
        />
        <v-select
          outlined
          v-model="gender"
          :items="genderItems"
          label="Gender"
          prepend-inner-icon="mdi-gender-male-female"
          dense
          :rules="[rules.required]"
        ></v-select>

        <v-text-field
          outlined
          v-model="date_of_birth"
          label="Date of Birth"
          prepend-inner-icon="mdi-calendar-search"
          dense
          type="date"
          :rules="[rules.required]"
        ></v-text-field>
        <v-text-field
          outlined
          v-model="address"
          label="Address"
          prepend-inner-icon="mdi-home"
          dense
          type="text"
          :rules="[rules.required]"
        ></v-text-field>

        <v-text-field
          outlined
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          dense
          prepend-inner-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append="showPassword = !showPassword"
          v-model="password"
          :rules="[rules.required, rules.password]"
        />

        <v-btn
          class="mt-2"
          color="oug"
          dark
          depressed
          @click="validate"
          elevation="24"
        >
          <v-icon left>mdi-account-plus</v-icon>
          Register
        </v-btn>
        <p></p>
        <router-link to="/Login">
          Already have an account
        </router-link>
      </v-form>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      registerValid: true,
      firstname: "",
      surname: "",
      email: "",
      mobile: "",
      gender: "",
      genderItems: ["Male", "Female"],
      date_of_birth: "",
      address: "",
      password: "",
      showPassword: false,
      rules: {
        required: (value) => !!value || "Required.",
        password: (value) => value.length >= 6 || "Min 6 characters",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
    };
  },
  methods: {
    register() {
      this.$store
        .dispatch("register", {
          firstname: this.firstname,
          surname: this.surname,
          email: this.email,
          mobile: this.mobile,
          gender: this.getDBGender(),
          date_of_birth: this.date_of_birth,
          address: this.address,
          password: this.password,
        })
        .then(() => {
          this.$store
            .dispatch("login", {
              username: JSON.parse(localStorage.getItem("user")).person.Login,
              password: this.password,
            })
            .then(() => {
              this.$toast.success(
                `We have successfully created a new user on the system with these details. 
                We have also sent you an email with your credentials !`,
                {
                  timeout: 3000,
                }
              );
              this.$router.push("/");
            })
            .catch((err) => {
              this.error = err.response.data.message;
            });
        });
    },
    formatDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    validate() {
      if (this.$refs.Register.validate()) {
        this.register();
      }
    },
    getDBGender() {
      let result = 20
      switch (this.gender) {
        case 'Male':
          result = 18
          break;
        case 'Female':
          result = 19
          break;
      }
      return result
    }
  },
};
</script>

<style scoped></style>
